export const MAX_FILE_SIZE = '10MB'
export const ACCEPTED_FILE_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]

export const ACCEPTED_CSV_FILE = ['text/csv']

const conversionFactors: { [unit: string]: number } = {
  KB: 1024,
  MB: 1024 ** 2,
  GB: 1024 ** 3,
  TB: 1024 ** 4
}

export const convertFileSizeToBytes = ({ size }: { size: string }) => {
  const sizeRegex = /^(\d+(?:\.\d+)?)\s*(KB|MB|GB|TB)$/i
  const match = size.match(sizeRegex)
  if (match) {
    const sizeInUnits = parseFloat(match[1])
    const unit = match[2].toUpperCase()

    const sizeInBytes = sizeInUnits * conversionFactors[unit]
    return sizeInBytes
  } else {
    throw new Error(`Invalid size format: ${size}`)
  }
}

export const regexUrlValidation =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/
