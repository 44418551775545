import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FCC } from '~/core/@types/global'
import { LockIcon } from '~/core/ui/FillIcons'
import { FormControlItem } from '~/core/ui/FormControlItem'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import {
  InlineEditingEditor,
  InlineEditingInput,
  InlineEditingInputNumber
} from '~/core/ui/InlineEditing'
import { InlineEditingNoActionsNativeSelect } from '~/core/ui/InlineEditingNoActions'
import { Input } from '~/core/ui/Input'
import { NativeSelect } from '~/core/ui/NativeSelect'
import { RichEditor } from '~/core/ui/RichEditor'
import { ISelectOption } from '~/core/ui/Select'
import { TypographyText } from '~/core/ui/Text'
import { Toggle } from '~/core/ui/Toggle'
import { Tooltip } from '~/core/ui/Tooltip'
import { cn } from '~/core/ui/utils'
import LongContentDisplay from '../LongContentDisplay'
import If from '~/core/ui/If'

export interface CustomFieldComponentType {
  type: 'text' | 'number' | 'paragraph' | 'toggle' | 'select'
  display: 'view_horizontal' | 'inline_editing' | 'default'
  isLoadingSubmitButton?: boolean
  name: string
  label?: string
  value?: any
  lockContent?: string
  onChange: (value: any) => void
  error?: FieldErrors<any>
  editingTriggerValidate?: () => Promise<boolean>
  viewDefaultPlaceholder?: string
  extraProps?: {
    options?: ISelectOption[]
  }
  classNameConfig?: {
    [type: string]: string
  }
  clientUserVisibility?: boolean
  classNameInlineEditting?: string
}

export const mappingCustomFieldIcon = (type: string): LucideIconName =>
  ((
    {
      text: 'Type',
      number: 'Hash',
      paragraph: 'AlignLeft',
      toggle: 'CheckSquare',
      select: 'ListChecks'
    } as { [key: string]: LucideIconName }
  )[type])

const InlineEditingLayout: FCC<{
  iconMenus?: LucideIconName
  lockContent?: string
  label?: string
  clientUserVisibility?: boolean
  className?: string
}> = ({
  children,
  iconMenus,
  lockContent,
  label,
  clientUserVisibility,
  className
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div>
        <div className="flex items-center space-x-2 py-1.5">
          <IconWrapper
            size={16}
            name={iconMenus}
            className="hidden flex-none text-gray-600 desktop:block"
          />
          <Tooltip content={label}>
            <TypographyText className="line-clamp-1 text-sm text-gray-700">
              {label}
            </TypographyText>
          </Tooltip>
          <If condition={clientUserVisibility === false}>
            <Tooltip
              classNameConfig={{ content: 'max-w-full' }}
              content={t('tooltip:client_cannot_view')}>
              <LockIcon />
            </Tooltip>
          </If>
          {lockContent && (
            <Tooltip
              classNameConfig={{ content: 'max-w-full' }}
              content={lockContent}>
              <LockIcon />
            </Tooltip>
          )}
        </div>
      </div>
      <div className={className}>{children}</div>
    </>
  )
}

const CustomField = ({
  display = 'default',
  viewDefaultPlaceholder = '-',
  isLoadingSubmitButton = false,
  label,
  name,
  type,
  value,
  lockContent,
  onChange,
  error,
  editingTriggerValidate,
  extraProps,
  classNameConfig,
  clientUserVisibility,
  classNameInlineEditting = ''
}: CustomFieldComponentType) => {
  const { t } = useTranslation()
  const defaultFormControlProps = {
    destructive: error && !!error?.[name],
    destructiveText: error && (error?.[name]?.message as string)
  }

  const defaultControlProps = {
    value,
    onChange
  }

  return (
    {
      default: {
        text: (
          <FormControlItem label={label} {...defaultFormControlProps}>
            <Input
              size="sm"
              {...defaultControlProps}
              placeholder={`${t('form:additional_field_input', {
                field_name: label
              })}`}
              destructive={defaultFormControlProps.destructive}
              className={classNameConfig?.text}
            />
          </FormControlItem>
        ),
        number: (
          <FormControlItem label={label} {...defaultFormControlProps}>
            <Input
              size="sm"
              inputType="number"
              placeholder={`${t('form:additional_field_input', {
                field_name: label
              })}`}
              {...defaultControlProps}
              destructive={defaultFormControlProps.destructive}
              className={classNameConfig?.number}
            />
          </FormControlItem>
        ),
        paragraph: (
          <FormControlItem label={label} {...defaultFormControlProps}>
            <RichEditor
              size="sm"
              limit={10000}
              placeholder={`${t('form:additional_field_input', {
                field_name: label
              })}`}
              onChange={(value) => {
                defaultControlProps.onChange(value)
              }}
              content={defaultControlProps.value}
              destructive={defaultFormControlProps.destructive}
              classNameWrapper={`min-h-[152px] ${classNameConfig?.paragraphWrapper}`}
              className={classNameConfig?.paragraph}
            />
          </FormControlItem>
        ),
        toggle: (
          <Toggle
            isChecked={defaultControlProps.value}
            name="view-switch"
            onCheckedChange={defaultControlProps.onChange}
            size="sm"
            text={label}
            toggle="trailing"
            className={classNameConfig?.toggle}
          />
        ),
        select: (() => {
          const selectedOption = (extraProps?.options || []).find(
            (option) => String(option.value) === value
          )
          return (
            <FormControlItem label={label} {...defaultFormControlProps}>
              <NativeSelect
                showDropdownIndicator={true}
                isClearable
                placeholder={`${t('form:additional_field_select')}`}
                size="md"
                value={selectedOption}
                onChange={(selectOption) => {
                  onChange((selectOption as ISelectOption)?.value || undefined)
                }}
                options={extraProps?.options || []}
                destructive={defaultFormControlProps.destructive}
                classNameOverride={{
                  loadingMessage: `${t('label:loading')}`,
                  noOptionsMessage: `${t('label:noOptions')}`
                }}
              />
            </FormControlItem>
          )
        })()
      },
      inline_editing: {
        text: (
          <InlineEditingLayout
            label={label}
            lockContent={lockContent}
            iconMenus={mappingCustomFieldIcon(type)}
            clientUserVisibility={clientUserVisibility}
            className={classNameInlineEditting}>
            <InlineEditingInput
              onChange={(newValue) => {
                onChange(newValue)
                return editingTriggerValidate
                  ? editingTriggerValidate()
                  : Promise.resolve(true)
              }}
              onCancelUpdate={(newValue) => onChange(newValue)}
              placeholder={
                t('form:additional_field_add', {
                  field_name: label
                }) as string
              }
              size="sm"
              className="-mx-px"
              loadingSubmit={isLoadingSubmitButton}
              value={value}
              tooltipActionCancel={{
                title: `${t('button:cancel')}`
              }}
              tooltipActionSave={{
                title: `${t('button:saveEnter')}`
              }}
              destructiveText={defaultFormControlProps.destructiveText}>
              <div className="flex items-center px-2 py-1.5 text-sm">
                {!!String(value || '').trim() ? (
                  <Tooltip content={value}>
                    <div className="line-clamp-1 text-gray-900">{value}</div>
                  </Tooltip>
                ) : (
                  <div className="line-clamp-1 text-gray-600">
                    {t('form:additional_field_add', {
                      field_name: label
                    })}
                  </div>
                )}
              </div>
            </InlineEditingInput>
          </InlineEditingLayout>
        ),
        number: (
          <InlineEditingLayout
            label={label}
            lockContent={lockContent}
            iconMenus={mappingCustomFieldIcon(type)}
            clientUserVisibility={clientUserVisibility}
            className={classNameInlineEditting}>
            <InlineEditingInputNumber
              onChange={(newValue) => {
                onChange(newValue)
                return editingTriggerValidate
                  ? editingTriggerValidate()
                  : Promise.resolve(true)
              }}
              onCancelUpdate={(newValue) => onChange(newValue)}
              placeholder={
                t('form:additional_field_add', {
                  field_name: label
                }) as string
              }
              size="sm"
              className="-mx-px"
              loadingSubmit={isLoadingSubmitButton}
              value={value}
              tooltipActionCancel={{
                title: `${t('button:cancel')}`
              }}
              tooltipActionSave={{
                title: `${t('button:saveEnter')}`
              }}
              destructiveText={defaultFormControlProps.destructiveText}>
              <div className="flex items-center px-2 py-1.5 text-sm">
                {!!value ? (
                  <div className="text-gray-900">{value}</div>
                ) : (
                  <div className="line-clamp-1 text-gray-600">
                    {t('form:additional_field_add', {
                      field_name: label
                    })}
                  </div>
                )}
              </div>
            </InlineEditingInputNumber>
          </InlineEditingLayout>
        ),
        paragraph: (
          <InlineEditingLayout
            label={label}
            lockContent={lockContent}
            iconMenus={mappingCustomFieldIcon(type)}
            clientUserVisibility={clientUserVisibility}
            className={classNameInlineEditting}>
            <InlineEditingEditor
              autoSave
              localStorageId={`custom-field-${name}`}
              className="min-w-full"
              limit={10000}
              showCount={true}
              loadingSubmit={isLoadingSubmitButton}
              destructiveText={defaultFormControlProps.destructiveText}
              onChange={(newValue) => {
                onChange(newValue)
                return editingTriggerValidate
                  ? editingTriggerValidate()
                  : Promise.resolve(true)
              }}
              placeholder={`${t('form:additional_field_add', {
                field_name: label
              })}`}
              size="sm"
              value={value}
              tooltipActionCancel={{
                title: `${t('button:cancel')}`
              }}
              tooltipActionSave={{
                title: `${t('button:saveEnter')}`
              }}>
              <div className="flex items-center px-2 py-1.5 text-sm">
                {value ? (
                  <LongContentDisplay
                    isHTML={true}
                    content={value}
                    limitLines={3}
                    textButtonProps={{
                      label: `${t('common:show_more')}`
                    }}
                    className="max-w-full text-sm text-gray-900 prose-p:!min-h-0"
                  />
                ) : (
                  <TypographyText className="line-clamp-1 text-sm text-gray-600">
                    {t('form:additional_field_add', {
                      field_name: label
                    })}
                  </TypographyText>
                )}
              </div>
            </InlineEditingEditor>
          </InlineEditingLayout>
        ),
        toggle: (
          <InlineEditingLayout
            label={label}
            lockContent={lockContent}
            iconMenus={mappingCustomFieldIcon(type)}
            clientUserVisibility={clientUserVisibility}
            className={classNameInlineEditting}>
            <div className="px-2 py-1.5">
              <Toggle
                isChecked={defaultControlProps.value}
                isDisabled={isLoadingSubmitButton}
                name="view-switch"
                onCheckedChange={(checked) => {
                  defaultControlProps.onChange(checked)
                  return editingTriggerValidate
                    ? editingTriggerValidate()
                    : Promise.resolve(true)
                }}
                size="sm"
              />
            </div>
          </InlineEditingLayout>
        ),
        select: (
          <InlineEditingLayout
            label={label}
            lockContent={lockContent}
            iconMenus={mappingCustomFieldIcon(type)}
            clientUserVisibility={clientUserVisibility}
            className={classNameInlineEditting}>
            <InlineEditingNoActionsNativeSelect
              onChange={(newValue) => {
                onChange(newValue?.value)
                return editingTriggerValidate
                  ? editingTriggerValidate()
                  : Promise.resolve(true)
              }}
              isDisabled={isLoadingSubmitButton}
              isClearable
              onCancelUpdate={(newValue) => {
                onChange(newValue?.value)
              }}
              options={extraProps?.options || []}
              placeholder={t('form:additional_field_select') as string}
              size="sm"
              classNameOverride={{
                loadingMessage: `${t('label:loading')}`,
                noOptionsMessage: `${t('label:noOptions')}`
              }}
              value={(extraProps?.options || [])?.find(
                (option) => option.value === value
              )}>
              <div className="px-2 py-1.5 text-sm">
                {!!(extraProps?.options || [])?.find(
                  (option) => option.value === value
                ) ? (
                  <div className="line-clamp-1 text-gray-900">
                    {
                      (extraProps?.options || [])?.find(
                        (option) => option.value === value
                      )?.supportingObj?.name
                    }
                  </div>
                ) : (
                  <div className="line-clamp-1 text-gray-600">
                    {t('form:additional_field_select') as string}
                  </div>
                )}
              </div>
            </InlineEditingNoActionsNativeSelect>
          </InlineEditingLayout>
        )
      },
      view_horizontal: {
        text: (
          <InlineEditingLayout
            label={label}
            iconMenus={mappingCustomFieldIcon(type)}
            clientUserVisibility={clientUserVisibility}
            className={classNameInlineEditting}>
            <div
              className={cn(
                'px-2 py-1.5 text-sm',
                value ? 'text-gray-900' : 'text-gray-600'
              )}>
              {value || viewDefaultPlaceholder}
            </div>
          </InlineEditingLayout>
        ),
        number: (
          <InlineEditingLayout
            label={label}
            iconMenus={mappingCustomFieldIcon(type)}
            clientUserVisibility={clientUserVisibility}
            className={classNameInlineEditting}>
            <div
              className={cn(
                'px-2 py-1.5 text-sm',
                value ? 'text-gray-900' : 'text-gray-600'
              )}>
              {value || viewDefaultPlaceholder}
            </div>
          </InlineEditingLayout>
        ),
        paragraph: (
          <InlineEditingLayout
            label={label}
            iconMenus={mappingCustomFieldIcon(type)}
            clientUserVisibility={clientUserVisibility}
            className={classNameInlineEditting}>
            <div className="px-2 py-1.5">
              <LongContentDisplay
                isHTML={true}
                content={value || viewDefaultPlaceholder}
                limitLines={3}
                textButtonProps={{
                  label: `${t('common:show_more')}`
                }}
                className={cn(
                  'max-w-full text-sm prose-p:!min-h-0',
                  value ? 'text-gray-900' : 'text-gray-600'
                )}
              />
            </div>
          </InlineEditingLayout>
        ),
        toggle: (
          <InlineEditingLayout
            label={label}
            iconMenus={mappingCustomFieldIcon(type)}
            clientUserVisibility={clientUserVisibility}
            className={classNameInlineEditting}>
            <div className="flex px-2 py-1.5">
              <Toggle
                isChecked={defaultControlProps.value}
                isDisabled={true}
                name="view-switch"
                size="sm"
              />
            </div>
          </InlineEditingLayout>
        ),
        select: (
          <InlineEditingLayout
            label={label}
            iconMenus={mappingCustomFieldIcon(type)}
            clientUserVisibility={clientUserVisibility}
            className={classNameInlineEditting}>
            <div
              className={cn(
                'px-2 py-1.5 text-sm',
                value ? 'text-gray-900' : 'text-gray-600'
              )}>
              {(extraProps?.options || []).find(
                (option) =>
                  String(option.value) === String(defaultControlProps.value)
              )?.supportingObj?.name || viewDefaultPlaceholder}
            </div>
          </InlineEditingLayout>
        )
      }
    } as {
      [key: string]: {
        [key: string]: React.ReactElement
      }
    }
  )[display][type]
}

export default CustomField
