'use client'

import { cn } from '~/core/ui/utils'
import { cva } from 'class-variance-authority'
import { ReactNode, forwardRef, useLayoutEffect, useState } from 'react'

const textareaDefaultVariants = cva(
  'py-2 px-3 inline-flex items-center justify-center w-full rounded dark:bg-gray-900 border border-solid',
  {
    variants: {
      variant: {
        NoDestructiveNoFocus: 'border-gray-300 dark:border-gray-600',
        NoDestructiveFocus:
          'border-primary-300 dark:border-primary-700 ring-1 ring-primary-300 dark:ring-primary-700',
        DestructiveNoFocus: 'border-red-300 dark:border-red-700',
        DestructiveFocus:
          'border-red-300 dark:border-red-700 ring-1 ring-red-300 dark:ring-red-700'
      },
      size: {
        md: 'text-base font-medium',
        sm: 'text-sm font-normal'
      },
      disabled: {
        default: '',
        disabled:
          'pointer-events-none bg-gray-50 dark:bg-gray-800 border-gray-200 dark:border-gray-700'
      },
      showCount: {
        default: '',
        show: 'pb-6'
      }
    },
    defaultVariants: {
      variant: 'NoDestructiveNoFocus',
      size: 'md',
      disabled: 'default',
      showCount: 'default'
    }
  }
)

const textareaGhostVariants = cva(
  'border border-solid px-2 py-1.5 inline-flex items-center justify-center w-full rounded hover:bg-gray-50 dark:bg-gray-900',
  {
    variants: {
      variant: {
        NoDestructiveNoFocus: 'border-transparent',
        NoDestructiveFocus:
          'border-primary-300 !hover:bg-white !bg-white shadow-select dark:border-primary-700 dark:shadow-dark-select',
        DestructiveNoFocus: 'border-red-300 !shadow-error !bg-white',
        DestructiveFocus:
          'border-red-300 !shadow-error !hover:bg-white !bg-white dark:border-primary-700 dark:shadow-dark-select'
      },
      size: {
        md: 'text-base font-medium',
        sm: 'text-sm font-normal'
      },
      disabled: {
        default: '',
        disabled: 'pointer-events-none bg-white dark:bg-gray-800'
      },
      showCount: {
        default: '',
        show: 'pb-6'
      }
    },
    defaultVariants: {
      variant: 'NoDestructiveNoFocus',
      size: 'md',
      disabled: 'default',
      showCount: 'default'
    }
  }
)

type TextAreaSizeProps = 'md' | 'sm'
type TextAreaVariantProps = 'default' | 'ghost'

interface TextAreaProps {
  onChange?: (value: string | number) => void
  onChangeEnter?: () => void
  defaultValue?: string | number
  value?: string | number
  isDisabled?: boolean
  className?: string
  limit?: number

  size?: TextAreaSizeProps
  type?: 'text' | 'number'
  destructive?: boolean
  placeholder?: string
  rows?: number
  cols?: number
  showCount?: boolean
  autoFocus?: boolean
  variant?: TextAreaVariantProps
  rowsAutoExpanded?: {
    default: number
    max: number
  }
}

const removeHTMLTags = (text: string) => text.replace(/(<([^>]+)>)/gi, '')

const Textarea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, ref) => {
    const {
      onChange = undefined,
      onChangeEnter = undefined,
      defaultValue = undefined,
      value = undefined,
      isDisabled = false,
      className = '',

      size = 'md',
      type = 'text',
      destructive = false,
      placeholder = '',
      rows = 6,
      cols = 50,
      rowsAutoExpanded,
      limit = 10000,
      showCount = true,
      variant = 'default',
      ...otherProps
    } = props
    const [focus, setFocus] = useState(false)

    useLayoutEffect(() => {
      // @ts-expect-error
      const textAreaRef = ref?.current

      if (Object.keys(rowsAutoExpanded || {}).length && textAreaRef) {
        textAreaRef.style.height = 'inherit'

        if (rowsAutoExpanded?.default) {
          textAreaRef.style.height = `${Math.max(
            textAreaRef.scrollHeight,
            rowsAutoExpanded.default
          )}px`
        }
      }
    }, [ref, rowsAutoExpanded, value])

    const renderedTextArea = () => (
      <textarea
        {...otherProps}
        onFocus={(e) => {
          setFocus(true)
          if (otherProps.autoFocus) {
            const val = e.target.value
            e.target.value = ''
            e.target.value = val
          }
        }}
        onBlur={() => setFocus(false)}
        ref={ref}
        rows={rows}
        cols={cols}
        defaultValue={defaultValue}
        placeholder={placeholder}
        className="h-full w-full resize-none bg-transparent text-gray-900 outline-none placeholder:text-gray-600 disabled:pointer-events-none disabled:text-gray-400 disabled:placeholder:text-gray-400 dark:text-gray-200 dark:placeholder:text-gray-400 dark:disabled:text-gray-700 dark:disabled:placeholder:text-gray-700"
        style={{
          maxHeight: rowsAutoExpanded?.max ? rowsAutoExpanded.max : 'auto'
        }}
        value={value}
        disabled={isDisabled}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value)
          }
        }}
        onKeyDown={(event) => {
          if (
            event.key === 'Enter' &&
            event.shiftKey == false &&
            onChangeEnter
          ) {
            event.preventDefault()
            onChangeEnter()
          }
        }}
      />
    )

    const renderedCount = () => (
      <div className="absolute bottom-0.5 right-[1px] z-10 flex items-center justify-end px-3">
        <p
          className={cn(
            'text-sm',
            isDisabled
              ? 'text-gray-400 dark:text-gray-700'
              : 'text-gray-600 dark:text-gray-400'
          )}>
          {removeHTMLTags(String(value || ''))?.length}/{limit}
        </p>
      </div>
    )

    if (variant === 'ghost') {
      return (
        <div
          className={cn(
            'relative',
            textareaGhostVariants({
              variant: `${destructive ? 'Destructive' : 'NoDestructive'}${
                focus ? 'Focus' : 'NoFocus'
              }`,
              size,
              disabled: isDisabled ? 'disabled' : 'default',
              showCount: showCount ? 'show' : 'default',
              className
            })
          )}>
          {renderedTextArea()}
          {showCount && renderedCount()}
        </div>
      )
    }

    return (
      <div
        className={cn(
          'relative',
          textareaDefaultVariants({
            variant: `${destructive ? 'Destructive' : 'NoDestructive'}${
              focus ? 'Focus' : 'NoFocus'
            }`,
            size,
            disabled: isDisabled ? 'disabled' : 'default',
            showCount: showCount ? 'show' : 'default',
            className
          })
        )}>
        {renderedTextArea()}
        {showCount && renderedCount()}
      </div>
    )
  }
)

Textarea.displayName = 'Textarea'

export { Textarea }
export type { TextAreaProps, TextAreaSizeProps, TextAreaVariantProps }
